import React from 'react';
import Box from 'components/box';
import Layout from 'components/layout';

const NotFound = () => (
  <Layout>
    <Box>Hey, are you lost? You have reached page no 404. Not Found. </Box>
  </Layout>
);

export default NotFound;
